.fileInput {
  height: 100px;
  width: 150px;
  background-color: #ebebeb;
  border-radius: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  object-fit: cover;
}
