.cardContainer {
  width: 200px;
  height: 265px;
  border-radius: 10px;
  border-style: solid;
  border-color: gray;
  border-width: 1px;
  margin: 8px;
}

.pictureContainer {
  display: flex;
  justify-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.profileImage {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin: 12px;
  margin-top: 48px;
  z-index: 200;
}

.profileBackground {
  width: 100%;
  height: 100px;
  object-fit: cover;
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.choreographerName {
  font-size: 18px;
  font-weight: 600;
  margin: 12px;
  padding-bottom: 8px;
}

.selectButton {
  width: 180px;
}
