.contentItem {
  background-color: #f5f5f5;
  margin-bottom: 1em;
  border-radius: 10px;
  padding: 2px 16px 0px 16px;
}

.fileInput {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16px;
}

.videoInputContainer {
  display: flex;
}

.videoInputContainer h1 {
  margin-left: 16px;
}

.videoRowInputContainer {
  display: flex;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-gap: 16px;
}
