.navigationBar {
  background-color: white !important;
  min-width: 300px !important;
  height: 100vmax !important;
  position: fixed !important;
  z-index: 10;
}

.navigationItem {
  background-color: white;
  margin: auto !important;
  width: 93% !important;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  border-radius: 8px;
}
