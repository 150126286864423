.loginForm {
  line-height: 36px;
}

.loginForm .input {
  margin-bottom: 20px;
  width: 75%;
}

.loginForm .button {
  width: 75%;
  height: 54px;
  background-color: black;
}

.loginForm .button:hover {
  opacity: 0.5;
  background-color: black;
}
