.header {
  padding-inline: 0px;
  z-index: 10;
}

.mainContent {
  /* To give space for the side navigation bar */
  margin-left: 300px;
  background-color: white;
}

.mainTitle {
  height: 64px;
  font-size: 36px;
  text-align: start;
  padding-left: 16px;
}
