.header {
  padding-inline: 0px;
}

.mainContent {
  background-color: white !important;
  padding-left: 1em;
  line-height: 36px;
}

.greetingContent {
  width: 50%;
  text-align: start;
  position: absolute;
  top: 30%;
  left: 5%;
  font-size: 64px;
}

.greetingContent .lightHeader {
  font-weight: normal;
}

.formContent {
  width: 30%;
  top: 30%;
  right: 5%;
  text-align: center;
  position: absolute;
}
