.navbar {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;
  position: fixed;
  top: 0;
  width: 100%;
  height: 64px;
}

.navbarSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbarSection h1 {
  color: black;
}

.navbarSection img {
  height: 48px;
  width: 48px;
  margin-right: 0.5em;
}
