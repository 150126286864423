.contentItem {
  height: 120px;
  background-color: #ebebeb;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

.contentThumbnail {
  background-color: black;
  width: 250px;
  height: 100px;
  border-radius: 10px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.contentTitleDescriptionContainer {
  width: 100%;
}

.contentTitle {
  text-align: start;
}

.contentDescription {
  text-align: start;
  text-overflow: ellipsis;
  /* word-wrap: break-word; */
  overflow: hidden;
  max-height: 3.6em;
}

.contentAction {
  width: 250px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.actionEdit {
  width: 200px;
  background-color: white;
  border-color: black;
  margin-bottom: 8px;
  color: black;
}

.contentAction .actionEdit:hover {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.actionDelete {
  width: 200px;
  background-color: red;
}

.contentAction .actionDelete:hover {
  background-color: #bd0000;
}
