.header {
  padding-inline: 0px;
}

.mainContent {
  background-color: white !important;
  padding-left: 1em;
}

.mainTitle {
  font-size: 64px;
  margin-top: 128px;
  margin-bottom: -12px;
}
