.header {
  padding-inline: 0px;
  z-index: 10;
}

.mainContent {
  /* To give space for the side navigation bar */
  margin-left: 300px;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.mainTitle {
  height: 64px;
  font-size: 36px;
  text-align: start;
}

.sectionTitle {
  font-size: 24px;
  text-align: start;
  padding-top: 16px;
}

.otherButton {
  margin-top: 8px;
  text-align: left;
}

.priceRowContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-gap: 16px;
}

.contentInputRowContainer {
  display: flex;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-gap: 16px;
  margin-top: 16px;
}

.imagePreview {
  height: 100px;
  width: 150px;
  border-radius: 10px;
}
